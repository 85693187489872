// @flow
import * as React from 'react';

import { type Props as BaseInputFieldProps } from '.';
import { isDefined } from '../../utils/ramda';

import { useLimitTextState } from './hooks';

const showLimitTextField = ({
  maxLength,
  allowOverLimit,
}: BaseInputFieldProps) => isDefined(maxLength) && !allowOverLimit;

function trivialHOC<Config: BaseInputFieldProps>(
  Component: React.AbstractComponent<Config>
): React.AbstractComponent<Config> {
  function LimitTextField(props: Config): React.Node {
    const limitTextState = useLimitTextState(props);

    return <Component {...props} {...limitTextState} />;
  }

  return (props: Config) => {
    if (showLimitTextField(props)) return <LimitTextField {...props} />;

    return <Component {...props} />;
  };
}

export default trivialHOC;
