// @flow
import { getInitialFilters } from '../../components/filters/utils';
import keys from '../../settings/storage';
import { stockFiltersInitialState } from '../../components/stock-filters/initialState';

export const getStockInitialFilters = (
  initialFilters: any
): {| __typename: string |} =>
  getInitialFilters(
    initialFilters,
    'ProductFilter',
    ['name', 'sku', 'selectedOnSiteId'],
    keys.stockFilters,
    // converts { min|max : n } => { min: n, max: null }
    // in order to fix broken query when there is a min and there is no max on or vice versa

    (filters) =>
      Object.keys(filters).reduce((acc, key) => {
        // $FlowFixMe
        let value = filters[key];
        if ((key === 'price' || key === 'quantity') && value !== null) {
          value = { min: null, max: null, ...value };
        }

        if (key === 'selectedOnSiteId') {
          // $FlowFixMe
          value = value !== null ? value.map(Number) : value;
        }

        if (key === 'shippingId' && value) {
          value = value.toString();
        }

        if (key === 'status' && value === 'ready') {
          // fixes querystring for old users
          return { ...acc, status: null };
        }
        // $FlowFixMe
        return { ...acc, [key]: value };
      }, {})
  );

export const stockInitialFilters = (getStockInitialFilters(
  stockFiltersInitialState
): {|
  __typename: string,
|});
