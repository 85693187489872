import useFeature from '../../hooks/use-feature';

export const stockFiltersInitialState = {
  archived: false,
  name: null,
  selectedOnSiteId: null,
  sku: null,
  price: { min: null, max: null },
  quantity: { min: null, max: null },
  status: null,
  withSales: undefined,
  rating: false,
  shippingId: null,
};

export function useStockFiltersInitialState() {
  const sortByRating = useFeature('sort_products_by_rating');

  return {
    initialStockFilters: {
      ...stockFiltersInitialState,
      rating: sortByRating || false,
    },
    initialStockFiltersLoading: sortByRating === null,
  };
}
