// @flow
import * as React from 'react';
import { equals } from 'ramda';
import cx from 'classnames';

import { useApolloDropdown } from '../../hooks/use-apollo-dropdown';
import ButtonDropdown from '../../basic-components/button-dropdown';
import Hr from '../../basic-components/hr';
import Button from '../../basic-components/button';
import Icon from '../../basic-components/icon';
import usePersistState from '../../hooks/use-persist-state';

import Toggler from './toggler';
import { getChangedFilters, addFiltersToQuery } from './utils';
import cs from './styles.pcss';
import t from './locale';
import { Provider } from './context';

import type { PopularFilter } from './typings';
import useWindowDimensions from '../../hooks/use-window-dimensions';

const WINDOW_HEIGHT_IS_TOO_SMALL = 400;

type Props<FT: {}> = {
  initialFilters: FT,
  filters: FT,
  localStorageKey: string,
  children: React.Node,
  onApply: (FT) => void,
  onReset: () => void,
  onButtonOpen: () => void,
  popularFilters?: PopularFilter[],
  preventReset?: string[],
  preserveQueryKeys: string[],
  isMobile?: boolean,
  adaptive?: boolean,
};

const calculateContainerHeight = (
  adaptive,
  isMobile,
  height,
  filtersBlockHeight
) => {
  if (adaptive && isMobile) {
    if (height && height <= WINDOW_HEIGHT_IS_TOO_SMALL) {
      return `calc(100vh - ${filtersBlockHeight}px)`;
    }
    return `calc(100vh - 124px - ${filtersBlockHeight}px)`;
  }
  return 'inherit';
};

export default function Filters<FT: {}>({
  initialFilters,
  filters,
  localStorageKey,
  children,
  popularFilters,
  onApply,
  onReset,
  onButtonOpen,
  preventReset = [],
  preserveQueryKeys,
  isMobile,
  adaptive = false,
}: Props<FT>): React.Node {
  const prevFilters = React.useRef(filters);
  const filtersBlock = React.useRef(null);
  const { height } = useWindowDimensions();
  const { closeDropdown } = useApolloDropdown('filtersDropdown');
  const [persistState, setPersistState] = usePersistState(localStorageKey);
  const [stateFilters, setStateFilters] = React.useState(filters);
  const [filtersBlockHeight, setFiltersBlockHeight] = React.useState(136);
  const [containerHeight, setContainerHeight] = React.useState(
    calculateContainerHeight(adaptive, isMobile, height, filtersBlockHeight)
  );

  React.useEffect(() => {
    // apply filters if it has changed when page init
    const newFilters = getChangedFilters(stateFilters, initialFilters);
    // $FlowFixMe
    if (Object.keys(newFilters).length === 1 && newFilters?.shippingId) {
      addFiltersToQuery(newFilters, preserveQueryKeys);
      setPersistState(newFilters);
      return;
    }

    addFiltersToQuery(persistState, preserveQueryKeys);
  }, []);

  React.useEffect(() => {
    if (!equals(prevFilters.current, filters)) {
      prevFilters.current = filters;
      setStateFilters(filters);
    }
  }, [filters]);

  React.useEffect(() => {
    if (adaptive && isMobile) {
      const filtersHeaderHeight = filtersBlock?.current?.offsetHeight;
      if (filtersHeaderHeight) {
        setFiltersBlockHeight(filtersHeaderHeight);
        setContainerHeight(
          calculateContainerHeight(
            adaptive,
            isMobile,
            height,
            filtersHeaderHeight
          )
        );
      }
    }
  });

  const changedFiltersCount = Object.keys(
    getChangedFilters(filters, initialFilters, preventReset)
  ).length;

  const hasChanges = Boolean(changedFiltersCount);

  const onApplyHandler = (appliedFilters) => {
    const newFilters = getChangedFilters(appliedFilters, initialFilters);
    addFiltersToQuery(newFilters, preserveQueryKeys);
    setPersistState(newFilters);
    closeDropdown();
  };

  const handleReset = () => {
    onReset();
    const preventedResetFilters = preventReset.reduce(
      (result, f) => ({ ...result, [f]: filters[f] }),
      {}
    );
    const newFilters = { ...initialFilters, ...preventedResetFilters };
    onApplyHandler(newFilters);
    setStateFilters(newFilters);
  };

  const handleApply = () => {
    onApply(stateFilters);
    onApplyHandler(stateFilters);
  };

  const handleFieldChange = (key, value) => {
    setStateFilters((prevStateFilters) => ({
      ...prevStateFilters,
      [key]: value,
    }));
  };

  const footer = (
    <div
      className={cx(cs.footer, {
        [cs.inModal]: adaptive && isMobile,
        [cs.fixed]:
          adaptive && isMobile && height && height > WINDOW_HEIGHT_IS_TOO_SMALL,
      })}
    >
      <Button
        size={adaptive && isMobile ? 'inModal' : 'small'}
        mode="secondary"
        onClick={handleReset}
      >
        {t('filtersControls.reset')}
      </Button>
      <Button
        size={adaptive && isMobile ? 'inModal' : 'small'}
        onClick={handleApply}
      >
        {t('filtersControls.apply')}
      </Button>
    </div>
  );

  return (
    <ButtonDropdown
      size="small"
      mode={hasChanges ? 'main' : 'secondary'}
      arrow={false}
      title={
        <>
          <Icon name="filter" className={cs.icon} />
          <span className={cs.buttonLabel}>
            {`${t('button')}${
              changedFiltersCount ? ` (${changedFiltersCount})` : ''
            }`}
          </span>
          {hasChanges && (
            <span className={cs.filtersCount}>{`${changedFiltersCount}`}</span>
          )}
        </>
      }
      resettable={hasChanges}
      onReset={handleReset}
      id="filtersDropdown"
      onButtonOpen={onButtonOpen}
      dropdownClassName={cs.dropDown}
      isMobile={isMobile}
      adaptive={adaptive}
    >
      <div className={cs.filters}>
        <div className={cs.body}>
          <Provider
            value={{
              values: stateFilters,
              onChange: handleFieldChange,
            }}
          >
            {popularFilters && (
              <div
                className={cx(cs.popular, {
                  [cs.popularInModal]: adaptive && isMobile,
                })}
                ref={filtersBlock}
              >
                <div className={cs.popularHeader}>
                  <div className={cs.popularTitle}>{t('popularFilters')}</div>
                  {adaptive && isMobile && (
                    <button
                      type="button"
                      autoFocus={false} //eslint-disable-line
                      className={cs.iconClose}
                      onClick={closeDropdown}
                    >
                      <Icon name="cancel" size={24} />
                    </button>
                  )}
                </div>
                <div
                  className={cx(cs.popularFilters, {
                    [cs.popularFiltersInModal]: adaptive && isMobile,
                  })}
                >
                  {popularFilters.map((filterProps) => (
                    // $FlowFixMe
                    <Toggler
                      key={filterProps.label}
                      initialValue={initialFilters[filterProps.name]}
                      {...filterProps}
                    />
                  ))}
                </div>
                <Hr className={cs.separator} />
              </div>
            )}
            <div className={cs.container} style={{ height: containerHeight }}>
              <div
                className={cx(cs.content, {
                  [cs.contentInModal]: adaptive && isMobile,
                })}
              >
                {children}
              </div>
              {height && height <= WINDOW_HEIGHT_IS_TOO_SMALL && footer}
            </div>
          </Provider>
        </div>
        {height && height > WINDOW_HEIGHT_IS_TOO_SMALL && footer}
      </div>
    </ButtonDropdown>
  );
}

export { default as TextFilter } from './text-field';
export { default as OptionsFilter } from './options-field';
export { default as RangeFilter } from './range-field';
export { default as MultiSelectFilter } from './multiselect-field';
export { default as MultiInputFilter } from './multiinput-field';
export { default as ShippingFilter } from './shipping-field';
