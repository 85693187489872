import { nanoid } from 'nanoid';
import { dissoc, keys, without, path, pathOr } from 'ramda';

import { logError } from '../utils/sentry';

import {
  fetchJSON,
  fetchJSONWithValidation,
  isCustomError,
} from '../utils/http';

import settings from '../settings';
import ANALYTICS from '../analytics/product';
import getConstantPrefixer from '../utils/actions';
import { format } from '../utils/string';
import { rollUpdatesByIndex, specificNotEmpty } from '../utils/product';
import { getCategoryErrors } from '../utils/product-categories';

import { showError } from './notifications';
import { open as openLightbox, close as closelightbox } from './lightbox';

export const NEW_PRODUCT_ID = 'new_product';

const prefix = getConstantPrefixer('PRODUCT');

export const SET_ACTIVE_ID = prefix('SET_ACTIVE_ID');

function setActiveId(id) {
  return { type: SET_ACTIVE_ID, id };
}

export const SET_ACTIVE_LISTING_ID = prefix('SET_ACTIVE_LISTING_ID');

export function setActiveListingId(id) {
  return { type: SET_ACTIVE_LISTING_ID, id };
}

function setOpen(open) {
  if (open) {
    return openLightbox('productForm');
  }

  return closelightbox('productForm');
}

export const SET_DATA = prefix('SET_DATA');

export function setData(data, initial, isNewProduct = false) {
  return { type: SET_DATA, data, initial, isNewProduct };
}

export const CLEAR_DATA = prefix('CLEAR_DATA');

function clearData() {
  return { type: CLEAR_DATA };
}

export const SET_CATEGORIES = prefix('SET_CATEGORIES');

export function setCategories(data) {
  return { type: SET_CATEGORIES, data };
}

export const SET_CATEGORIES_LOADING = prefix('SET_CATEGORIES_LOADING');

function setCategoriesLoading(loading) {
  return { type: SET_CATEGORIES_LOADING, loading };
}

export const SET_LOADING = prefix('SET_LOADING');

function setLoading(loading) {
  return { type: SET_LOADING, loading };
}

export const SET_SAVING = prefix('SET_SAVING');

function setSaving(saving) {
  return { type: SET_SAVING, saving };
}

export const SET_ARCHIVING = prefix('SET_ARCHIVING');
const setArchiving = (archiving) => ({ type: SET_ARCHIVING, archiving });

export const SET_SAVE_ERROR = prefix('SET_SAVE_ERROR');
const setSaveError = (error) => ({ type: SET_SAVE_ERROR, error });

export const SHOW_SUCCESS_MESSAGE = prefix('SHOW_SUCCESS_MESSAGE');

function showSuccessMessage(visible) {
  return { type: SHOW_SUCCESS_MESSAGE, visible };
}

export const UPDATE_FORM = prefix('UPDATE_FORM');

export function updateForm(data) {
  return { type: UPDATE_FORM, data };
}

export const CLEAR_CHANGES = prefix('CLEAR_CHANGES');

export function clearChanges() {
  return { type: CLEAR_CHANGES };
}

export function showErrorAndClose(error) {
  return (dispatch) => {
    dispatch(showError(error));
    dispatch(setOpen(false));
    dispatch(setSaveError(null));
  };
}

export function searchCategories(
  siteId,
  keyword,
  lang = '',
  productFormSearch = false
) {
  return (dispatch, getState) => {
    const { method, action: actionTemplate } = settings.form.categories.search;
    const action = format(
      actionTemplate,
      siteId,
      encodeURIComponent(keyword),
      lang
    );

    dispatch(setCategoriesLoading(true));

    fetchJSON(action, { method })
      .then(({ ok, data }) => {
        if (ok) {
          let categories = data;

          // try to detect possible incompatibilities with product
          // when searching from product form
          if (productFormSearch) {
            const {
              product: { data: productData },
            } = getState();
            categories = data.map((category) => ({
              ...category,
              errors: getCategoryErrors(productData, category),
            }));
          }
          dispatch(setCategoriesLoading(false));
          dispatch(setCategories(categories));
        } else {
          const e = new Error(
            `Unhandled searchCategories error: ${method} ${action} response status is not ok`
          );
          logError(e, { extra: { keyword } });
        }
      })
      .catch((error) => {
        logError(error, {
          extra: { action, method, keyword },
        });

        dispatch(showError(error));
      });
  };
}

function loadData(productOrListingId, fromURL, byListing) {
  return (dispatch) => {
    dispatch(setCategoriesLoading(false));
    dispatch(setLoading(true));

    const { action: actionTemplate, method } = byListing
      ? settings.form.product.getByListing
      : settings.form.product.get;
    const action = actionTemplate.replace('{id}', productOrListingId);

    fetchJSONWithValidation(action, { method })
      .then((data) => {
        dispatch(setData(data, true));
        if (byListing) {
          dispatch(setActiveId(data.id));
        }

        if (
          data.problems &&
          data.problems.some((err) => err.code === 'free_limit_reached')
        ) {
          ANALYTICS.limitReached();
        }
      })
      .catch((error) => {
        if (fromURL && error.response && error.response.status === 404) {
          dispatch(setOpen(false));
          dispatch(setSaveError(null));
          return;
        }

        logError(error, { extra: { action, method } });
        dispatch(showErrorAndClose(error));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function addProduct() {
  return (dispatch) => {
    dispatch(setSaveError(null));
    dispatch(setOpen(true));
  };
}

export function openForm(id, fromURL) {
  return (dispatch) => {
    dispatch(loadData(id, fromURL));
    dispatch(setSaveError(null));
    dispatch(setOpen(true));
    dispatch(setActiveId(id));
    ANALYTICS.openEdit();
  };
}

export function openFormByListing(listingId) {
  return (dispatch) => {
    dispatch(loadData(listingId, true, true));
    dispatch(setSaveError(null));
    dispatch(setOpen(true));
    dispatch(setActiveListingId(listingId));
    ANALYTICS.openEdit();
  };
}

export function closeForm() {
  return (dispatch) => {
    dispatch(clearData());
    dispatch(clearChanges());
    dispatch(setSaveError(null));
    dispatch(setOpen(false));
    dispatch(setActiveId());
    dispatch(setActiveListingId());
  };
}

function mapUpdates(data, updates) {
  const result = {};

  const productUpdates = updates.filter(
    (update) => update.id.search(/^product\./) !== -1
  );

  productUpdates.forEach((update) => {
    const { field, changeset } = update;
    const value = changeset[field];
    result[field] = { value };
  });

  const specificsUpdates = updates.filter(
    (update) => update.id.search(/^specific\./) !== -1
  );

  if (specificsUpdates.length > 0) {
    result.specifics = rollUpdatesByIndex(
      data.specifics,
      specificsUpdates
    ).filter((s) => !s.deleted && specificNotEmpty(s));
  }

  const listingUpdates = updates.filter(
    (update) => update.id.search(/^listing\./) !== -1
  );

  if (listingUpdates.length) {
    result.listings = data.listings.map((l) => ({ id: l.id }));
  }

  listingUpdates.forEach((update) => {
    const { field, changeset } = update;
    const value =
      field === 'vat' ? Number(changeset[field] || 0) : changeset[field];
    const { listingId } = changeset;

    const index = result.listings.findIndex((l) => l.id === listingId);

    if (index === -1) {
      // ?
    } else {
      result.listings[index][field] = { value };

      // site_id to enable site if it was turned off before the changes
      if (changeset.selected) {
        result.listings[index].site_id = data.listings[index].showcase.siteId;
      }
    }
  });

  const variationUpdates = updates.filter(
    (update) => update.id.search(/^variation\./) !== -1
  );

  if (variationUpdates.length) {
    result.variations = data.variations.map((v) => ({
      id: v.id,
      temporaryId: v.temporaryId,
    }));
  }

  variationUpdates.forEach((update) => {
    const { field, changeset } = update;
    const value =
      field === 'price' ? parseFloat(changeset[field]) : changeset[field];
    const { variationId } = changeset;

    const index = result.variations.findIndex(
      (v) => v.id === variationId || v.temporaryId === variationId
    );

    if (index === -1) {
      // ?
    } else {
      result.variations[index][field] = { value };
    }
  });

  const variationListingUpdates = updates.filter(
    (update) => update.id.search(/^variationListing\./) !== -1
  );

  if (variationListingUpdates.length && !result.variations) {
    result.variations = data.variations.map((v) => ({
      id: v.id,
      temporaryId: v.temporaryId,
    }));
  }

  variationListingUpdates.forEach((update) => {
    const { field, changeset } = update;
    const value =
      field === 'price' ? parseFloat(changeset[field]) : changeset[field];
    const { variationId } = changeset;
    const { variationListingId } = changeset;

    const index = result.variations.findIndex(
      (v) => v.id === variationId || v.temporaryId === variationId
    );

    if (index === -1) {
      // ?
    } else {
      let listingIndex = -1;

      if (!result.variations[index].listings) {
        result.variations[index].listings = data.variations[index].listings.map(
          (vl) => ({ listing_id: vl.listingId })
        );
      }

      listingIndex = result.variations[index].listings.findIndex(
        (l) => l.listing_id === variationListingId
      );

      if (listingIndex === -1) {
        // ?
      } else {
        result.variations[index].listings[listingIndex][field] = { value };
      }
    }
  });

  const variationDeletedUpdates = updates.filter(
    (update) => update.id.search(/^variationDeleted\./) !== -1
  );

  if (variationDeletedUpdates.length && !result.variations) {
    result.variations = data.variations.map((v) => ({
      id: v.id,
      temporaryId: v.temporaryId,
    }));
  }

  variationDeletedUpdates.forEach((update) => {
    const {
      changeset: { variationId },
    } = update;
    result.variations = result.variations.filter(
      (v) => v.id !== variationId && v.temporaryId !== variationId
    );
  });

  if (variationUpdates.length || variationListingUpdates.length) {
    result.variations = result.variations.filter(
      (v) =>
        !v.temporaryId ||
        without(['id', 'sku', 'temporaryId'], keys(v)).length > 0
    );
    result.variations = result.variations.map((v) => {
      const variation = { ...v };

      // If no specifics filled in a new variation,
      // we should add array with empty strings
      // to avoid "different set of specifics" error
      if (!variation.id && !variation.values) {
        variation.values = {
          value: new Array(data.values.length).fill(''),
        };
      }

      return dissoc('temporaryId', variation);
    });
  }

  return result;
}

const fixManageOption = (body) => {
  if (!body.managed) return body;
  return { ...body, managed: body.managed.value };
};

export const SET_SUGGESTED_CATEGORIES = prefix('SET_SUGGESTED_CATEGORIES');
const setSuggestedCategories = (listingId, data) => ({
  type: SET_SUGGESTED_CATEGORIES,
  listingId,
  data,
});

export function getSuggestedCategories(
  listingId,
  showcaseId,
  autoSelectSingle = false
) {
  // https://www.notion.so/ebaymag/69793514ab6a4d889b8e9565498c24f9#fb16aeec968d4fb5b8655fc9b5337c38

  return (dispatch, getState) => {
    const {
      product: { data: productData, updates: productUpdates },
    } = getState();
    const { method, action } = settings.form.categories.suggest;

    const body = {
      showcaseId,
      product: {
        language: { value: 'en' },
        ...fixManageOption(mapUpdates(productData, productUpdates)),
        id: productData.id === NEW_PRODUCT_ID ? undefined : productData.id,
      },
    };

    return fetchJSON(action, { method, body })
      .then(({ ok, data }) => {
        if (ok) {
          dispatch(
            setSuggestedCategories(
              listingId,
              data.categories.map((category) => ({
                ...category,
                errors: getCategoryErrors(productData, category),
              }))
            )
          );

          // Automatically select first category when switching showcase and there is only one category suggested
          if (data.categories.length === 1 && autoSelectSingle) {
            const category = data.categories[0];

            dispatch(
              updateForm({
                id: `listing.category.${listingId}`,
                field: 'categoryId',
                defaultValue: null,
                value: category,
                changeset: { listingId, categoryId: category.id },
              })
            );
          }
        } else {
          const e = new Error(
            `Unhandled getSuggestedCategories error: ${method} ${action} response status is not ok`
          );
          logError(e);
          dispatch(setSuggestedCategories(listingId, []));
        }
      })
      .catch((error) => {
        logError(error, {
          extra: { action, method },
        });

        dispatch(setSuggestedCategories(listingId, []));
        dispatch(showError(error));
      });
  };
}

export function saveChanges() {
  return (dispatch, getState) => {
    dispatch(setSaving(true));
    dispatch(setSaveError(null));
    dispatch(showSuccessMessage(false));

    const {
      product: { data: productData, updates: productUpdates },
    } = getState();

    const productId = productData.id;

    const body = fixManageOption(mapUpdates(productData, productUpdates));

    const { action: actionTemplate, method } = settings.form.product.update;

    const handleError = (action) => (error) => {
      ANALYTICS.save(false);
      logError(error, { extra: { action, method, body } });
      if (isCustomError(error)) {
        dispatch(setSaveError(error.message));
      } else {
        dispatch(showError(error));
      }
    };

    const updateItem = (action, updateItemBody) =>
      fetchJSONWithValidation(action, { method, body: updateItemBody })
        .then((data) => {
          ANALYTICS.save(true);
          dispatch(setData(data));
          dispatch(clearChanges());
          dispatch(showSuccessMessage(true));
        })
        .catch(handleError(action))
        .finally(() => {
          dispatch(setSaving(false));
        });

    if (productId === NEW_PRODUCT_ID) {
      const { action: newAction, method: newMethod } =
        settings.form.product.add;

      const skuPath = ['variations', 0, 'sku', 'value'];
      const sku = path(skuPath, body) || path(skuPath, productData) || '';
      const newBody = {
        id: null,
        variations: [{ sku: { value: '' } }],
      };

      // First create item, and then update its content
      fetchJSONWithValidation(newAction, { method: newMethod, body: newBody })
        .then((data) => {
          const variations = pathOr({}, ['variations', 0], body);

          const updatedBody = {
            ...body,
            id: data.id,
            listings: body.listings
              ? body.listings.map((l, i) => ({ ...l, id: data.listings[i].id }))
              : undefined,
            variations: [
              {
                ...variations,
                id: data.variations[0].id,
                sku: { value: sku },
              },
            ],
          };
          const action = actionTemplate.replace('{id}', data.id);

          updateItem(action, updatedBody);
        })
        .catch(() => {
          handleError(newAction);
          dispatch(setSaving(false));
        });
    } else {
      const action = actionTemplate.replace('{id}', productId);
      updateItem(action, body);
    }
  };
}

export const republishProduct = () => (dispatch, getState) => {
  dispatch(setSaving(true));
  dispatch(setSaveError(null));

  const {
    product: {
      data: { id },
    },
  } = getState();
  const { action, method } = settings.form.product.republish;
  const requestUrl = action.replace('{id}', id);

  return fetchJSONWithValidation(requestUrl, { method })
    .then((data) => {
      ANALYTICS.republish(true);
      dispatch(setData(data));
      dispatch(clearChanges());
      dispatch(showSuccessMessage(true));
    })
    .catch((error) => {
      ANALYTICS.republish(false);
      logError(error, { extra: { action, method } });
      if (isCustomError(error)) {
        dispatch(setSaveError(error.message));
      } else {
        dispatch(showError(error));
      }
    })
    .finally(() => {
      dispatch(setSaving(false));
    });
};

export function deleteProduct(productId) {
  return (dispatch) => {
    const { action: actionTemplate, method } = settings.form.product.delete;
    const action = actionTemplate.replace('{id}', productId);

    return fetchJSONWithValidation(action, { method })
      .catch((error) => {
        logError(error.message, { extra: { action, method } });
        dispatch(showError(error));
      })
      .then(() => dispatch(setLoading(false)))
      .then(() => ANALYTICS.delete());
  };
}

export function setArchivedStatus(productId, archived) {
  return (dispatch) => {
    dispatch(setArchiving(true));

    const { action: actionTemplate, method } = settings.form.product.update;
    const action = actionTemplate.replace('{id}', productId);
    const body = { archived };

    return fetchJSONWithValidation(action, { method, body })
      .then(() => {
        if (archived) {
          ANALYTICS.archive();
        } else {
          ANALYTICS.unarchive();
        }
      })
      .catch((error) => {
        logError(error, { extra: { action, method, body } });
        dispatch(showError(error));
      })
      .then(() => {
        dispatch(setArchiving(false));
      });
  };
}

export function addVariation() {
  return (dispatch, getState) => {
    const {
      product: { data: prevData },
    } = getState();

    const newVariation = {
      sku: { value: '' },
      ean: { value: '' },
      isbn: { value: '' },
      upc: { value: '' },
      price: { value: 0 },
      quantity: { value: 0 },
      pictures: { value: [] },
      thumbnails: { value: [] },
      largeThumbnails: { value: [] },
      values: { value: new Array(prevData.values.length).fill('') },
      listings: prevData.listings.map((l) => ({
        listingId: l.id,
        price: {
          value: 0,
          custom: false,
        },
        exchangeRate: l.showcase.exchangeRate,
      })),
      newVariation: true,
      temporaryId: nanoid(),
    };

    dispatch(
      setData({
        ...prevData,
        variations: [...prevData.variations, newVariation],
      })
    );
  };
}
