import * as React from 'react';
import { useQuery } from '@apollo/client';
import { path } from 'ramda';

import Banner from '../../../basic-components/banner';
import GetMarkdownStatus from './operations.graphql';
import { useOnboardingTour } from '../../../hooks/use-onboarding-tour';
import t from './locale';

const bannerKey = 'ebaymagAttentionBanner';

// 2024/07/02 update, apply this banner as markdown campaign
export default function AttentionBanner() {
  const { data, loading } = useQuery(GetMarkdownStatus);
  const { onboardingShown, onboardingStatusLoading } = useOnboardingTour();


  if (loading) {
    return null;
  }

  // const zifAllowed = path(['viewer', 'zif'], data);
  // const hipo = path(['viewer', 'hipo'], data);
  // const sea = path(['viewer', 'sea'], data);
  // const india = path(['viewer', 'india'], data);
  const inMarkdown = path(['viewer', 'markdownListingsMarkedAt'], data);

  return (
    inMarkdown && (
      <Banner
        message={
          <>
            <b>{t('intro')}</b>
            <br />
            <span>{t('text')}</span>
          </>
        }
        id={bannerKey}
        color="indigo"
        localKey={bannerKey}
        hidden={onboardingStatusLoading || onboardingShown}
        // hidden={!zifAllowed || onboardingStatusLoading || onboardingShown}
      />
    )
  );
}
