import { InMemoryCache, makeVar } from '@apollo/client';
import { uniq } from 'ramda';

import { stockInitialFilters } from '../pages/stock/initialState';
import {
  getShowcaseId,
  siteInitialFilters,
} from '../pages/showcase-listings/initialState';
import { parcelsInitialFilters } from '../pages/parcels/initialState';
import { initialOnboardingStep } from '../components/onboarding-tour/initialState';

// Common
export const lightboxesVar = makeVar([]);
export const dropdownsVar = makeVar([]);
export const notificationsVar = makeVar([]);

// Stock
export const changesVar = makeVar([]);
export const activeShippingProfileVar = makeVar(null);
export const selectedShowcaseVar = makeVar(null);
export const stockFiltersVar = makeVar(stockInitialFilters);
export const changeSuccessIdsVar = makeVar([]);
export const changeErrorIdsVar = makeVar([]);

// Site
export const listingChangesVar = makeVar([]);
export const currentShowcaseIdVar = makeVar(getShowcaseId());
export const siteFiltersVar = makeVar(siteInitialFilters);
export const listingChangeSuccessIdsVar = makeVar([]);
export const listingChangeErrorIdsVar = makeVar([]);

// Parcels
export const parcelsFiltersVar = makeVar(parcelsInitialFilters);
export const parcelsSortVar = makeVar(null);
export const activeParcelVar = makeVar(null);
export const hiddenParcelsVar = makeVar([]);

// Shipping Profiles
export const addressInEditingVar = makeVar(null);
export const selectedShippingProfileVar = makeVar(null);

// Onboarding
export const currentOnboardingStepVar = makeVar(initialOnboardingStep);
export const continuedOnboardingStepVar = makeVar(0);
export const backedOnboardingStepVar = makeVar(0);
export const confirmedPartOnboardingStepVar = makeVar(0);
export const onboardingSkipStepsVar = makeVar([3]);
export const onboardingTipShownVar = makeVar(false);

function pagination(keyArgs = false) {
  return {
    keyArgs,
    read(existing = {}) {
      return {
        ...existing,
        nodes: existing.nodes || [],
        pageInfo: { ...(existing.pageInfo || { hasNextPage: true }) },
      };
    },
    merge(existing = {}, incoming = {}) {
      // If cursor was not changed, we probably wrote to the cache directly
      // (e.g. deleted some items or changed them),
      // so incoming nodes would be the right choice
      const localChanges =
        existing.pageInfo &&
        incoming.pageInfo &&
        existing.pageInfo.endCursor === incoming.pageInfo.endCursor;

      if (localChanges) {
        return {
          ...existing,
          ...incoming,
          nodes: uniq(incoming.nodes || []),
          pageInfo: {
            ...(existing.pageInfo || {}),
            ...(incoming.pageInfo || {}),
          },
        };
      }

      // If cursor changed, we fetched more data with pagination,
      // so here we should merge nodes
      return {
        ...existing,
        ...incoming,
        nodes: uniq([...(existing.nodes || []), ...(incoming.nodes || [])]),
        pageInfo: {
          ...(existing.pageInfo || {}),
          ...(incoming.pageInfo || {}),
        },
      };
    },
  };
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        productSyncStates: {
          keyArgs: ['syncType'],
          merge: false,
        },
        changes: {
          read() {
            return changesVar();
          },
        },
        changeSuccessIds: {
          read() {
            return changeSuccessIdsVar();
          },
        },
        changeErrorIds: {
          read() {
            return changeErrorIdsVar();
          },
        },
        listingChangeSuccessIds: {
          read() {
            return listingChangeSuccessIdsVar();
          },
        },
        listingChangeErrorIds: {
          read() {
            return listingChangeErrorIdsVar();
          },
        },
        activeShippingProfile: {
          read() {
            return activeShippingProfileVar();
          },
        },
        lightboxes: {
          read() {
            return lightboxesVar();
          },
        },
        dropdowns: {
          read() {
            return dropdownsVar();
          },
        },
        notifications: {
          read() {
            return notificationsVar();
          },
        },
        selectedShowcase: {
          read() {
            return selectedShowcaseVar();
          },
        },
        stockFilters: {
          read() {
            return stockFiltersVar();
          },
        },
        listingChanges: {
          read() {
            return listingChangesVar();
          },
        },
        currentShowcaseId: {
          read() {
            return currentShowcaseIdVar();
          },
        },
        siteFilters: {
          read() {
            return siteFiltersVar();
          },
        },
        parcelsFilters: {
          read() {
            return parcelsFiltersVar();
          },
        },
        parcelsSort: {
          read() {
            return parcelsSortVar();
          },
        },
        activeParcel: {
          read() {
            return activeParcelVar();
          },
        },
        hiddenParcels: {
          read() {
            return hiddenParcelsVar();
          },
        },
        addressInEditing: {
          read() {
            return addressInEditingVar();
          },
        },
        selectedShippingProfile: {
          read() {
            return selectedShippingProfileVar();
          },
        },
        currentOnboardingStep: {
          read() {
            return currentOnboardingStepVar();
          },
        },
        continuedOnboardingStep: {
          read() {
            return continuedOnboardingStepVar();
          },
        },
        backedOnboardingStep: {
          read() {
            return backedOnboardingStepVar();
          },
        },
        confirmedPartOnboardingStep: {
          read() {
            return confirmedPartOnboardingStepVar();
          },
        },
        onboardingSkipSteps: {
          read() {
            return onboardingSkipStepsVar();
          },
        },
        onboardingTipShown: {
          read() {
            return onboardingTipShownVar();
          },
        },
        productImportState: {
          merge: true,
        },
        productExcelImportState: {
          merge: true,
        },
        products: pagination(['filters']),
        profiles: pagination([]),
        listings: pagination(['filters', 'showcaseId', 'language', 'ids']),
        parcels: pagination(['filters', 'sortBy', 'ids']),
        categories: pagination(['search', 'siteId', 'used', 'language']),
      },
    },
    Address: {
      fields: {
        country: {
          keyArgs: ['code'],
          merge: true,
        },
      },
    },
    Account: {
      fields: {
        store: {
          merge: true,
        },
      },
    },
    User: {
      fields: {
        logisticServices: {
          merge: true,
        },
        logisticTosStatus: {
          merge: true,
        },
      },
    },
    Product: {
      fields: {
        listings: {
          merge: false,
        },
      },
    },
    Listing: {
      fields: {
        problems: {
          merge: false,
        },
        variations: {
          merge: false,
        },
      },
    },
    Parcel: {
      fields: {
        orders: {
          merge: false,
        },
        tracking: {
          merge: true,
        },
      },
    },
  },
});
