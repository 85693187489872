// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import Field from '../field';
import UploadFile from '../upload-file';
import UploadFileButton, {
  type Props as UploadFileButtonProps,
} from '../upload-file-button';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Upload =
  | { state: 'waiting', id: string, file: File }
  | { state: 'uploading', id: string, file: File, progress: number }
  | { state: 'error', id: string, file: File, error: * }
  | { state: 'finished', id: string, file: File, meta: * };

type Props = {
  ...UploadFileButtonProps,
  label: string | null,
  maxUploads: number,
  disabled?: boolean,
  uploads: Array<Upload>,
  onDelete: (id: string) => void,
  onChange: (files: Array<File> | FileList) => void,
  size?: 'normal' | 'big',
};

export default function UploadFileField({
  uploads,
  maxUploads = 5,
  label,
  uploadButtonLabel,
  accept,
  disabled,
  onChange,
  onDelete,
  size = 'normal',
  id,
  ...rest
}: Props): React.Node {
  const uploadsCount = uploads.filter(
    (upload) => upload.state !== 'error'
  ).length;
  const labelWithCounter = (
    <>
      <div>{label}</div>
      <div className={cx('counter')}>
        {uploadsCount} / {maxUploads}
      </div>
    </>
  );

  return (
    <Field {...rest} label={labelWithCounter} fieldClassName={cx('field')}>
      <div className={cx('container', size)}>
        {uploads.map((upload) => (
          <UploadFile
            key={upload.id}
            fileName={upload.file.name}
            size={upload.file.size}
            state={upload.state}
            onDelete={() => onDelete(upload.id)}
            error={upload.state === 'error' ? upload.error : null}
          />
        ))}
        {(!uploads || uploads.length < maxUploads) && (
          <UploadFileButton
            onChange={(e) => onChange(e.target.files)}
            uploadButtonLabel={uploadButtonLabel}
            accept={accept}
            disabled={disabled}
            size={size}
            id={id}
          />
        )}
      </div>
    </Field>
  );
}
