export const initialState = {
  data: {
    id: 0,
    url: '',
    deleted: false,
    banned: false,
    language: '',
    status: '',
    managed: true,
    title: {
      value: '',
      required: true,
    },
    subtitle: {
      value: '',
    },
    description: {
      value: '',
    },
    condition: {
      value: '',
    },
    conditionId: {
      value: '',
    },
    conditionDescription: {
      value: '',
    },
    pictures: {
      value: [],
    },
    thumbnails: {
      value: [],
    },
    largeThumbnails: { value: [] },
    shippingProfileId: {
      value: -1,
    },
    uuid: {
      value: '',
    },
    values: [],
    specifics: [],
    listings: [],
    variations: [],
    allowDestroy: false,
    allowedLanguages: [],
  },
  state: {
    open: false,
    changed: false,
    loading: false,
    categoriesLoading: false,
    saving: false,
    archiving: false,
    isNewProduct: false,
    showSuccessMessage: false,
    activeId: undefined,
    activeListingId: undefined,
    saveError: null,
  },
  updates: [],
  errors: [],
  options: {
    conditions: [
      { value: '1000' },
      { value: '1500' },
      { value: '1750' },
      { value: '2000' },
      { value: '2010' },
      { value: '2020' },
      { value: '2030' },
      { value: '2500' },
      { value: '2750' },
      { value: '3000' },
      { value: '4000' },
      { value: '5000' },
      { value: '6000' },
      { value: '7000' },
    ],
  },
};
