// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import { getLocale } from '../../utils/i18n/index';
import { useDropdown } from '../../hooks/use-dropdown';
import VerticalMenu from '../../basic-components/vertical-menu';
import Icon from '../../basic-components/icon';
import analytics from '../../analytics/navigation';
import ButtonWithTooltip from '../button-with-tooltip';
import FlagIcon from '../../basic-components/flag-icon';

import t from './locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Lang = {
  name: string,
  title: string,
  id: string,
};

type Props = {
  className?: string,
  page?: string,
  isMobile?: boolean,
  mobileOption?: () => React.Node,
};

const getLangs = (): Lang[] => [
  { name: 'en', title: 'English', id: 'en' },
  { name: 'ру', title: 'Русский', id: 'ru' },
  { name: 'es', title: 'Español', id: 'es' },
  { name: 'fr', title: 'Français', id: 'fr' },
  { name: 'it', title: 'Italiano', id: 'it' },
  { name: 'pt', title: 'Português', id: 'pt' },
  { name: 'cn', title: '简体中文', id: 'zh' },
  { name: 'ja', title: '日本語', id: 'ja' },
  { name: 'ko', title: '한국어', id: 'ko' },
];

const getLangHref = (lang) => {
  if (typeof location !== 'undefined') {
    const search = location.search
      .replace(/[?&]locale=\w{2}/gi, '')
      .replace(/[?&]/, '');
    const param = search ? `${search}&locale=${lang}` : `locale=${lang}`;
    return `${location.href.replace(/\?.*$/, '')}?${param}`;
  }

  return '';
};

export default function LangSwitcher({
  className,
  page,
  isMobile,
  mobileOption,
}: Props): React.Node {
  const { opened, handleToggle, ref } = useDropdown('langDropdown');

  const current = getLocale();
  const langs = getLangs();
  const currentLocale = langs.find((l) => l.id === current) || langs[0];

  const options = langs.map((l) => ({
    icon: l.id,
    flag: true,
    text: l.title,
    href: getLangHref(l.id),
    onClick: () => {
      analytics.languageSwitch(l.id);
    },
    selected: l.id === current,
  }));

  if (isMobile) {
    return (
      <div ref={ref} className={cs.mobileLangSwitcher}>
        <button
          type="button"
          onClick={handleToggle}
          className={cs.changeLangButton}
        >
          <FlagIcon
            name={currentLocale.id}
            wrapperClassName={cs.optionFlagIcon}
            active
          />{' '}
          {t('changeLang')}{' '}
          <Icon name="selectSmall" className={cs.arrowRight} />
        </button>

        {opened && mobileOption && (
          <div className={cs.changeLangWrapper}>
            <div className={cs.changeLangHead}>
              <button
                type="button"
                onClick={handleToggle}
                className={cs.changeLangClose}
              >
                <Icon name="arrowLeft" />
              </button>
              <div className={cs.changeLangTitle}>{t('changeLang')}</div>
            </div>
            <div className={cs.changeLangBody}>{options.map(mobileOption)}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cx('langSwitcher', className)} ref={ref}>
      <ButtonWithTooltip
        className={cx('button', { opened })}
        onClick={handleToggle}
        text={t('tooltip')}
        tooltipHidden={opened}
        gap={-6}
      >
        <div className={cs.switcher}>
          {currentLocale.name}
          <Icon name="selectSmall" className={cs.arrow} />
        </div>
      </ButtonWithTooltip>

      <VerticalMenu
        id="langDropdown"
        page={page}
        opened={opened}
        options={options}
        withSelected
        className={cs.menu}
        arrowClassName={cs.menu}
      />
    </div>
  );
}
